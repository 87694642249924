import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { dashboardStore, analyticsStore } from '@/store';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let ReposicaoList = class ReposicaoList extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.headers = [
            {
                show: true,
                text: 'Nome do aluno',
                value: 'aluno_nome',
                table: 'aluno',
                type: 'str',
            },
            {
                show: true,
                table: 'livro',
                text: 'Livro',
                type: 'str',
                value: 'livro_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Situação',
                value: 'situacao',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Livro entregue',
                value: 'livro_entregue',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Primeira parcela do livro',
                value: 'primeira_parcela_status',
            },
            { show: true, table: 'turma', text: 'Turma', value: 'codigo_referencia' },
            {
                show: true,
                table: 'turma',
                text: 'Previsão de inicio da turma',
                value: 'previsao_inicio',
            },
        ];
    }
    get totals() {
        return analyticsStore.totals;
    }
    async buscar() {
        analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await dashboardStore.getDashboardEntregaLivroQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsEntregaLivro();
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(dashboardStore.getDashboardEntregaLivroQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que nao tem defaultFilter precisa ter o buscar() aqui
        await this.doSearchTotals();
        this.buscar();
    }
};
ReposicaoList = __decorate([
    Component({
        components: {
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
            OpenNewTabComponent,
        },
    })
], ReposicaoList);
export default ReposicaoList;
